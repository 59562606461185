import React, { FC } from "react";
import { graphql, Link } from "gatsby";
import { Button } from "~components/utils/button";
import { formatDate } from "~utils";
import { PageLayout } from "~components/templates/page-layout";
import * as styles from "./cve.module.scss";
import { ArticleLinkList } from "../../components/articles/article-link-list/index";

type Props = {
  data: GatsbyCvePageQuery;
};

const CVEPage: FC<Props> = ({ data }) => {
  if (!data.allContentfulCve) return null;
  return (
    <PageLayout
      paddingPattern="larger-than-header"
      meta={{
        title: "CVE",
        description:
          "We are pushing our best effort to investigate and identify security vulnerabilities on various types of software and hardware that are currently available on the internet. As a result of our enormous effort, our researchers were the first to identify and to report the following vulnerabilities.",
      }}
    >
      <main>
        <div className={styles.CVE}>
          <div className={styles.CVE_Top}>
            <h1>CVE</h1>
            <p className={styles.CVE_Intro}>
              We, Flatt Security, are striving harder to improve the
              cybersecurity for the local and global community. We strongly
              believe that identifying and eradicating cyber weaknesses on
              products will eventually make the internet a much safer medium to
              communicate and utilize. To accomplish our objective, we are
              pushing our best effort to investigate and identify security
              vulnerabilities on various types of software and hardware that are
              currently available on the internet. As a result of our enormous
              effort, our researchers were the first to identify and to report
              the following vulnerabilities.
            </p>
            <Link to="/research">
              <Button
                className={styles.CVE_Button}
                buttonTheme="Secondary"
                buttonSize="Large"
              >
                See blog articles
              </Button>
            </Link>
          </div>
          <div className={styles.CVE_ListSectionWrapper}>
            <div className={styles.CVE_ListSectionContainer}>
              <ul className={styles.CVE_List}>
                {data.allContentfulCve.edges.map((edge, i) => (
                  <li className={styles.CVE_ListItem} key={`cve_${i}`}>
                    <Link
                      className={styles.CVE_ListItemLink}
                      to={`/cve/${edge.node.cveId}`}
                    >
                      <p className={styles.CVE_Date}>
                        {formatDate(edge.node.date, "YYYY/MM/DD")}
                      </p>
                      <p className={styles.CVE_Id}>{edge.node.cveId}</p>
                      <p className={styles.CVE_TitleEn}>{edge.node.titleEn}</p>
                      <p className={styles.CVE_TitleJp}>{edge.node.titleJp}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </PageLayout>
  );
};

export default CVEPage;

export const query = graphql`
  query CVEPage {
    allContentfulCve(sort: { fields: date, order: DESC }) {
      edges {
        node {
          cveId
          date
          titleJp
          titleEn
        }
      }
    }
  }
`;
