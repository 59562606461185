// extracted by mini-css-extract-plugin
export var CVE = "cve-module--CVE--sZCUg";
export var CVE_Button = "cve-module--CVE_Button--Vbkxz";
export var CVE_Date = "cve-module--CVE_Date--27fbl";
export var CVE_Id = "cve-module--CVE_Id--N3q2O";
export var CVE_Intro = "cve-module--CVE_Intro--xynCu";
export var CVE_List = "cve-module--CVE_List--syQXc";
export var CVE_ListItem = "cve-module--CVE_ListItem--W97LZ";
export var CVE_ListItemLink = "cve-module--CVE_ListItemLink--PkFCC";
export var CVE_ListSectionContainer = "cve-module--CVE_ListSectionContainer--0Cmbo";
export var CVE_ListSectionWrapper = "cve-module--CVE_ListSectionWrapper--9D9bZ";
export var CVE_TitleEn = "cve-module--CVE_TitleEn--Z+cmU";
export var CVE_TitleJp = "cve-module--CVE_TitleJp--do5wB";
export var CVE_Top = "cve-module--CVE_Top--udb4R";